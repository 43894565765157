import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import styles from '../components/contact.module.css'

const ContactPage = props => {
  const formLocations = [
    {name:'Auckland', email:'auckland@elevenpr.co.nz'},
    {name:'Sydney', email:'sydney@elevenpr.com.au'},
    {name:'Melbourne', email:'melbourne@elevenpr.com.au'},
  ];

  const { data, errors } = props
  const [inputSubject, inputSubjectChanged] = React.useState("");
  const [inputLocation, inputLocationChanged] = React.useState(formLocations[0].email);
  const [inputBody, inputBodyChanged] = React.useState("");

  const subjectChange = e => {inputSubjectChanged(e.target.value);}
  const locationChange = e => {inputLocationChanged(e.target.value);}
  const bodyChange = e => {inputBodyChanged(e.target.value);}


  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = data.site

  if (!site) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={'Contact'} description={site.headline}/>
      <Container>
        <h1 className={styles.headerTitle}>
          {site.headline}
        </h1>

        <div className="formSection">
          <form>
            <div className={styles.grid} >
              <input className={styles.subject} name="subject" type="text" placeholder="Subject" onChange={ subjectChange }/>

              <div className={styles.messageContainer}>
                <h2 className={styles.messageHeader} > How can we help you?</h2>


              <div className={styles.locationContainer} >
                {formLocations.map((item, i) => (
                  <label key={`form-${item.name}`} className={styles.locationRadio}>
                    <input
                      type="radio"
                      value={item.email}
                      checked={inputLocation === item.email}
                      onChange={locationChange}
                      />
                    <span>{item.name}</span>
                  </label>
                ))}
              </div>

                <textarea className={styles.message} rows="10" name="body" placeholder="Type your message" onChange={ bodyChange }></textarea>
              </div>
              <div>
                <a className={styles.submitButton} href={`mailto:${inputLocation}?subject=${inputSubject}&body=${inputBody}`}>
                  <span>Send Message</span>
                </a>
              </div>
            </div>
          </form>
        </div>


      </Container>
    </Layout>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      headline: 'No title'
    }
  }
}
export default ContactPage

export const query = graphql`
  query ContactPageQuery {
    site: sanityPageContact(_id: { regex: "/(drafts.|)pageContact/" }) {
      headline
    }
  }
  
`